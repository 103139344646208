import { useState } from 'react';
import useAPI from './useAPI';
import { APIError } from '../../shared/api_errors';
import { useAuth } from '../useAuth';
import { useProfile } from '../useProfile';

export type RadiusDiagnosticsMessageType = 'no-response' | 'failed' | 'ok' | 'retry' | 'fatal' | 'unexpected';

export interface RadiusDiagnosticsResponseMessage {
	message: string;
	type: RadiusDiagnosticsMessageType;
}

export interface RadiusDiagnosticsResponse {
	messages: RadiusDiagnosticsResponseMessage[];
	radius_response?: { [key: string]: unknown };
}

export interface DiagnosticPayload {
	connectivity_user: string;
	connectivity_password: string;
}

export default function useAPIDiagnostics() {
	const { tenant } = useProfile();
	const { user } = useAuth();
	const api = useAPI(true, user?.access_token, tenant);

	const [diagnosticResponse, setDiagnosticResponse] = useState<RadiusDiagnosticsResponse | undefined>(undefined);
	const [diagnosticLoading, setDiagnosticLoading] = useState<boolean>(false);
	const [error, setError] = useState<APIError | undefined>();

	async function runDiagnostic(payload: DiagnosticPayload) {
		setDiagnosticResponse(undefined);
		setDiagnosticLoading(true);

		try {
			const result = await api.post('/radius-diagnostics', { body: payload });
			setError(undefined);
			setDiagnosticResponse(result);
			return result;
		} catch (err) {
			if (typeof err === 'string') {
				setError(new APIError(err));
			} else if (err instanceof Error) {
				setError(new APIError(err.message));
			} else if (err instanceof APIError) {
				setError(err);
			}
		} finally {
			setDiagnosticLoading(false);
		}
	}

	return { runDiagnostic, diagnosticResponse, diagnosticLoading, error };
}
