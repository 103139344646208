import React, { useEffect } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import WrapperPage from '../WrapperPage';
import Input from '@/components/Input';
import FormGroup from '@/slices/FormGroup';
import Button from '@/components/Button';
import useAPIDiagnostics, {
	RadiusDiagnosticsMessageType,
	RadiusDiagnosticsResponseMessage,
} from '@/hooks/api/useAPIDiagnostics';
import {
	Info,
	CircleSlash,
	ShieldCheck,
	ShieldQuestion,
	ShieldOff,
	History,
	ShieldX,
	LoaderCircle,
} from 'lucide-react';

interface DiagnosticFormInput {
	connectivity_user: string;
	connectivity_password: string;
}

export default function DiagnosticsPage() {
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const connectivityUserQuery = searchParams.get('connectivity_user');
	const connectivityPasswordQuery = searchParams.get('connectivity_password');

	const { control, handleSubmit, setValue, reset } = useForm<DiagnosticFormInput>();

	// Populate the form fields from the query string values, if available.
	useEffect(() => {
		if (connectivityUserQuery) {
			setValue('connectivity_user', connectivityUserQuery);
		}
		if (connectivityPasswordQuery) {
			setValue('connectivity_password', connectivityPasswordQuery);
		}
	}, [connectivityUserQuery, connectivityPasswordQuery, setValue]);

	const { runDiagnostic, diagnosticResponse, diagnosticLoading, error } = useAPIDiagnostics();

	const onSubmit: SubmitHandler<DiagnosticFormInput> = async (data) => {
		console.log('Form Data:', data);
		await runDiagnostic(data);
	};

	function handleClear() {
		reset({
			connectivity_user: '',
			connectivity_password: '',
		});
	}

	return (
		<WrapperPage title="Diagnostics">
			<div className="m-4 flex flex-col gap-4">
				<FormGroup title="Gravity PPPoE User">
					<form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-6">
						<div className="flex flex-col gap-2">
							<Controller
								control={control}
								name="connectivity_user"
								rules={{ required: 'Connectivity user is required' }}
								render={({ field }) => <Input label="Connectivity User" {...field} />}
							/>

							<Controller
								control={control}
								name="connectivity_password"
								rules={{ required: 'Connectivity password is required' }}
								render={({ field }) => <Input label="Connectivity Password" {...field} />}
							/>
						</div>

						<div className="flex gap-2">
							<Button type="submit" text="Run Diagnostic" disabled={diagnosticLoading} />
							<Button
								text="Clear"
								variant="extraLight"
								disabled={diagnosticLoading}
								onClick={handleClear}
							/>
						</div>
					</form>

					{error && <p className="mt-2 text-red-600">Error: {error.message}</p>}
				</FormGroup>

				<div className="flex flex-grow gap-6">
					<div className="flex-1 w-1/2">
						<FormGroup title="Diagnostic Messages">
							<div className="flex flex-col gap-2">
								{diagnosticLoading ? (
									<LoaderCircle className="animate-spin" />
								) : diagnosticResponse ? (
									diagnosticResponse.messages.map((msg, index) => (
										<div key={index} className="border-b p-2 last:border-none">
											<DiagnosticMessage msg={msg} />
										</div>
									))
								) : (
									<p>No diagnostic messages yet.</p>
								)}
							</div>
						</FormGroup>
					</div>

					<div className="flex-1 w-1/2">
						<FormGroup title="RADIUS Response">
							{diagnosticLoading ? (
								<LoaderCircle className="animate-spin" />
							) : diagnosticResponse && diagnosticResponse.radius_response ? (
								<pre className="overflow-scroll custom-scrollbar">
									{JSON.stringify(diagnosticResponse.radius_response, null, 2)}
								</pre>
							) : (
								<p>No RADIUS response available.</p>
							)}
						</FormGroup>
					</div>
				</div>
			</div>
		</WrapperPage>
	);
}

function DiagnosticMessage({ msg }: { msg: RadiusDiagnosticsResponseMessage }) {
	const iconMapping: Record<RadiusDiagnosticsMessageType, React.FC<{ className?: string }>> = {
		'no-response': () => <CircleSlash className="text-light" />,
		failed: () => <ShieldX className="text-danger" />,
		ok: () => <ShieldCheck className="text-success" />,
		retry: () => <History className="text-light" />,
		fatal: () => <ShieldOff className="text-danger" />,
		unexpected: () => <ShieldQuestion className="text-warning" />,
	};

	const IconComponent = iconMapping[msg.type] || Info;

	return (
		<div className="flex items-center space-x-2">
			<IconComponent className="w-5 h-5" />
			<span className="text-sm">{msg.message}</span>
		</div>
	);
}
